var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('user-dashboard-layout',[_c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Claims table")])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","loading":_vm.btnLoading.ASSIGMENT},on:{"click":function($event){return _vm.parseSftp('ASSIGMENT')}}},[_vm._v(" fetch assignments ")]),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","loading":_vm.btnLoading.STATUS},on:{"click":function($event){return _vm.parseSftp('STATUS')}}},[_vm._v(" fetch statuses ")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","search":_vm.search,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-col',[(_vm.table && _vm.table.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.table,"items-per-page":15,"item-key":"id","dense":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.date_of_loss",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.date_of_loss))+" ")]}},{key:"item.ia_reviewed_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.ia_reviewed_date))+" ")]}},{key:"item.reviewed_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.reviewed_date))+" ")]}},{key:"item.qa_approved_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.qa_approved_date))+" ")]}},{key:"item.contacted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.contacted))+" ")]}},{key:"item.inspected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("americanDateFormat")(item.inspected))+" ")]}}],null,false,3759176713)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }