<template >
    <user-dashboard-layout>
        <v-container fluid class="ma-0">
            <v-row>
                <v-col>
                    <h1>Claims table</h1>
                </v-col>

                <v-col class="text-right">
                    <v-btn
                        color="primary"
                        @click="parseSftp('ASSIGMENT')"
                        :loading="btnLoading.ASSIGMENT"
                        class="mx-5"
                    > fetch assignments
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="parseSftp('STATUS')"
                        :loading="btnLoading.STATUS"
                        class="mx-5"
                    > fetch statuses
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-text-field
                    dense
                    outlined
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    :search="search"
                    hide-details
                ></v-text-field>
            </v-row>
            <v-row>
                <v-col>
                  <v-data-table v-if="table && table.length > 0"
                                :headers="headers"
                                :items="table"
                                :items-per-page="15"
                                class="elevation-1"
                                item-key="id"
                                dense
                                :search="search"
                  >
                    <template v-slot:item.date_of_loss="{ item }" >
                      {{ item.date_of_loss | americanDateFormat }}
                    </template>

                    <template v-slot:item.ia_reviewed_date="{ item }" >
                      {{ item.ia_reviewed_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.reviewed_date="{ item }" >
                      {{ item.reviewed_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.qa_approved_date="{ item }" >
                      {{ item.qa_approved_date | americanDateFormat }}
                    </template>
                    <template v-slot:item.contacted ="{ item }" >
                      {{ item.contacted  | americanDateFormat }}
                    </template>
                    <template v-slot:item.inspected ="{ item }" >
                      {{ item.inspected  | americanDateFormat }}
                    </template>

                  </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import ClaimsApi from '@/api/Claims';
import dayjs from 'dayjs'

export default {
    name: "ClaimsIndex",
    components: {UserDashboardLayout},

    data: function () {
        return {
            table: [],
            src: 'ASSIGMENT',
            search: '',
            btnLoading: {
                ASSIGMENT: false,
                STATUS:false,
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    computed: {
        //TODO maybe easy get headers from BD comments (see Claims migration in ->comment())
        headers() {
            return [
              {"text": "Claim #", "value": "claim_claim"},
              {"text": "Claim Rep", "value": "claim_rep"},
              {"text": "Claim Rep XN Address", "value": "xn_address"},
              {"text": "Insured Name", "value": "insured"},
              {"text": "Address", "value": "address"},
              {"text": "City", "value": "city"},
              {"text": "State", "value": "state"},
              {"text": "Zip", "value": "zip"},
              {"text": "Date of Loss", "value": "date_of_loss"},
              {"text": "Received", "value": "received"},
              {"text": "Delivered", "value": "delivered"},
              {"text": "Contacted", "value": "contacted"},
              {"text": "Inspected", "value": "inspected"},
              {"text": "Appointment", "value": "appointment"},
              {"text": "Returned", "value": "returned"},
              {"text": "Reviewed Date", "value": "reviewed_date"},
              {"text": "QA Approved Date", "value": "qa_approved_date"},
              {"text": "QA Approval", "value": "qa_approval"},
              {"text": "IA Review Status", "value": "ia_review_status"},
              {"text": "IA Reviewed Date", "value": "ia_reviewed_date"},
            ]
        }
    },
    filters: {
        americanDateFormat: (date) => {
            if (!date){
                return null;
            }

            return dayjs(date).format('MM/DD/YYYY')
        }
    },
    methods: {
        async fetchData() {
            ClaimsApi.all().then(resp => {
                this.table =  resp.data;
            })
        },
        parseSftp(src) {
            this.btnLoading[src] = true
            this.reportParse = null
            window.axios.get('/api/sftp_and_parse/' + src).then(res => {
                this.reportParse = res.data
                this.fetchData().then(()=>{
                    this.btnLoading[src] = false
                })
            })
        }
    }

}
</script>

<style scoped>

</style>
